import { useStorage } from "@vueuse/core";
import { printHelperPorts } from "@mono/constants/lib/printHelperPorts";

const basePrintHelperUrl = "http://localhost";

const bucketUrl =
  "https://mezan-print-helper-releases.s3.eu-west-1.amazonaws.com/releases";
const LATEST_SEMVER_VERSION = "1.4.1";

const printHelperAppDownloadUrls = {
  development: `${bucketUrl}/development/Mezan+Print+Helper+Development+v${LATEST_SEMVER_VERSION}+Installer.exe`,
  staging: `${bucketUrl}/staging/Mezan+Print+Helper+Staging+v${LATEST_SEMVER_VERSION}+Installer.exe`,
  production: `${bucketUrl}/live/Mezan+Print+Helper+v${LATEST_SEMVER_VERSION}+Installer.exe`,
};

let runningPortIdx = 0;
const loading = ref(false);
const currentAppVersion = ref<string>();
let timeout: NodeJS.Timeout;

export const usePrintHelper = () => {
  // composables
  const printHelperStorage = useStorage<{ appPortIdx: number }>(`printhelper`, {
    appPortIdx: -1,
  });
  const config = useRuntimeConfig();

  // computed
  const isConnected = computed(() => printHelperStorage.value.appPortIdx > -1);

  const showDownloadBtn = computed(
    () =>
      !isConnected.value || currentAppVersion.value !== LATEST_SEMVER_VERSION
  );

  const url = computed(() =>
    isConnected.value
      ? `${basePrintHelperUrl}:${printHelperPorts[runningPortIdx]}`
      : undefined
  );

  const tryConnect = async () => {
    loading.value = true;

    if (timeout) {
      clearTimeout(timeout);
    }

    if (isConnected.value) {
      runningPortIdx = printHelperStorage.value.appPortIdx;
    }

    if (runningPortIdx >= printHelperPorts.length) {
      runningPortIdx = 0;
      loading.value = false;
      return;
    }

    try {
      const { data, error } = await useFetch<{
        msg: string;
        live: boolean;
        version: string;
      }>(`${basePrintHelperUrl}:${printHelperPorts[runningPortIdx]}`);

      if (error.value || !data.value?.live) {
        throw error.value || new Error("Network Error");
      }

      currentAppVersion.value = data.value.version;
      printHelperStorage.value.appPortIdx = runningPortIdx;
      loading.value = false;
    } catch {
      ++runningPortIdx;
      if (printHelperStorage.value.appPortIdx > -1) {
        printHelperStorage.value.appPortIdx = -1;
      }

      timeout = setTimeout(tryConnect, 500);
    }
  };

  const preloadPrintHelper = async () => {
    if (!isConnected.value) {
      return;
    }

    const { getTestPrintUrl } = usePrint();

    try {
      await useFetch<{
        msg: string;
        live: boolean;
        version: string;
      }>(
        `${basePrintHelperUrl}:${
          printHelperPorts[runningPortIdx]
        }${`?printUrl=${getTestPrintUrl()}`}`
      );
    } catch (error) {
      console.error("Error preloading print helper:", error);
    }
  };

  return {
    async init() {
      await tryConnect();
    },
    printHelperStorage,
    url,
    downloadUrl:
      printHelperAppDownloadUrls[
        config.public.API_TARGET as keyof typeof printHelperAppDownloadUrls
      ],
    isConnected,
    tryConnect,
    preloadPrintHelper,
    loading,
    showDownloadBtn,
  };
};
