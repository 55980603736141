const online = ref(false);

export const useNetworkCheck = () => {
  const config = useRuntimeConfig();
  const { refreshTokens } = useAuthStore();
  const { authStorage } = useAuthStorage();
  const url =
    config.public.API_TARGET !== "staging"
      ? config.public.API_URL
      : config.public.API_URL.replace("http:", "https:").replace("80", "3030");

  const healthURL = url + "/health";

  const isOnline = async () => {
    try {
      const { data, error } = await useFetch<{ live: boolean }>(healthURL);

      if (error.value || !data.value?.live) {
        throw error.value || new Error("Network Error");
      }

      if (
        online.value === false &&
        authStorage.value.refreshToken != null &&
        authStorage.value.token == null
      ) {
        await refreshTokens();
      }

      online.value = true;

      return true;
    } catch {
      online.value = false;
      return false;
    }
  };

  return {
    online,
    isOnline,
  };
};
