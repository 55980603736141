import { usePrintHelper } from "@/composables/usePrintHelper";
import type { IndexedDBSchemas } from "@/composables/useIndexDB";

export type TerminalSchema = IndexedDBSchemas["terminal"];

interface PrinterType {
  name: string;
  displayName: string;
  description: string;
  status: number;
  isDefault: boolean;
  options: {
    "printer-location": string;
    "printer-make-and-model": string;
    system_driverinfo: string;
  };
}

const INIT_TERMINAL_OPTIONS: TerminalSchema = {
  printers: [],
  callNumbersEnabled: false,
  showProductsBarcode: false,
  showProductsTotalQuantity: false,
  showReferenceBarcode: false,
  locale: "ar",
};

export const useTerminalStore = defineStore("terminal", () => {
  // states
  const printersList = ref<PrinterType[]>([]);
  const callNumbersCount = ref(0);
  const isBarcodeMode = ref(true);

  const options = ref<TerminalSchema>(INIT_TERMINAL_OPTIONS);

  // composables
  const { cache } = useIndexDB();
  const { url: printHelperUrl } = usePrintHelper();

  // watchers
  watch(
    () => options.value.callNumbersEnabled,
    async () => {
      await cache.terminal.updateProperty(
        "callNumbersEnabled",
        options.value.callNumbersEnabled
      );
    }
  );
  watch(
    () => options.value.showProductsBarcode,
    async () => {
      await cache.terminal.updateProperty(
        "showProductsBarcode",
        options.value.showProductsBarcode
      );
    }
  );
  watch(
    () => options.value.showProductsTotalQuantity,
    async () => {
      await cache.terminal.updateProperty(
        "showProductsTotalQuantity",
        options.value.showProductsTotalQuantity
      );
    }
  );
  watch(
    () => options.value.showReferenceBarcode,
    async () => {
      await cache.terminal.updateProperty(
        "showReferenceBarcode",
        options.value.showReferenceBarcode
      );
    }
  );
  watch(
    () => options.value.locale,
    async () => {
      await cache.terminal.updateProperty("locale", options.value.locale);
    }
  );

  // fetchPrinterList
  const fetchPrinterList = async () => {
    if (!printHelperUrl.value) return;
    try {
      const { data, error } = await useFetch<PrinterType[]>(
        `${printHelperUrl.value}/printers`
      );

      if (error.value || !data.value) {
        throw error.value || new Error("no data");
      }

      printersList.value = data.value;
    } catch (error) {
      console.error("Error fetching printer list:", error);
    }
  };

  // togglePrinter
  const togglePrinter = async (printerName: string) => {
    const cloned = [...options.value.printers];
    const index = cloned.findIndex(({ name }) => name === printerName);

    if (index > -1) {
      cloned.splice(index, 1);
    } else {
      cloned.push({ name: printerName, copies: 1 });
    }

    options.value.printers = cloned;

    await cache.terminal.updateProperty(
      "printers",
      JSON.parse(JSON.stringify(cloned))
    );
  };

  // updatePrinterCopies
  const updatePrinterCopies = async (printerIdx: number, copies: number) => {
    options.value.printers[printerIdx].copies = copies;

    await cache.terminal.updateProperty(
      "printers",
      JSON.parse(JSON.stringify(options.value.printers))
    );
  };

  // incrementCallNumber
  const incrementCallNumber = () => {
    const edited = callNumbersCount.value + 1;
    callNumbersCount.value = edited;

    return edited;
  };

  // resetCounter
  const resetCounter = () => {
    callNumbersCount.value = 0;
  };

  // init
  const init = async () => {
    const [, terminalData] = await Promise.allSettled([
      fetchPrinterList(),
      cache.terminal.get(),
    ]);

    if (terminalData.status === "fulfilled") {
      if (!terminalData.value) {
        await cache.terminal.save("1", INIT_TERMINAL_OPTIONS);
      }

      if (terminalData.value) {
        options.value = {
          ...INIT_TERMINAL_OPTIONS,
          ...JSON.parse(JSON.stringify(terminalData.value)),
        };
      } else {
        options.value = INIT_TERMINAL_OPTIONS;
      }

      const defaultPrinter = printersList.value.find(
        (printer) => printer.isDefault
      );

      if (defaultPrinter && !options.value.printers.length) {
        options.value.printers.push({ name: defaultPrinter.name, copies: 1 });
      }
    }
  };

  // clear
  const clear = async () => {
    await cache.terminal.clear();
    printersList.value = [];
    callNumbersCount.value = 0;
    isBarcodeMode.value = true;

    options.value = INIT_TERMINAL_OPTIONS;
  };

  return {
    // getters
    printersList,
    callNumbersCount,
    isBarcodeMode,
    options,
    // setters
    init,
    clear,
    togglePrinter,
    incrementCallNumber,
    resetCounter,
    fetchPrinterList,
    updatePrinterCopies,
  };
});
