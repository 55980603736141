import revive_payload_client_s5RVQYZ7Kh from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9HN1P8CCwm from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0gR4xrZ0ye from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9J296YKOi6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XJqq5sMcRG from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GcpJMV8KYD from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_f7Zq6oBQUy from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Rt1wvYoGLz from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.4.5_vue@3.5.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/pos/.nuxt/components.plugin.mjs";
import prefetch_client_A45YlfBOgf from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.4.5_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_puByCiRaRV from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.21.3_vue@3.5.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/pos/.nuxt/unocss.mjs";
import pwa_icons_vOfwvGoMCY from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.21.3_vite@5.4.5_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_0hc9zT1UqI from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.21.3_vite@5.4.5_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import _05_sentry_j5CkoUzNHJ from "/vercel/path0/apps/pos/plugins/05.sentry.ts";
import trpc_0KeCZOBKLF from "/vercel/path0/apps/pos/plugins/trpc.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/apps/pos/plugins/vue-query.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/apps/pos/plugins/vuetify.ts";
export default [
  revive_payload_client_s5RVQYZ7Kh,
  unhead_9HN1P8CCwm,
  router_0gR4xrZ0ye,
  payload_client_9J296YKOi6,
  navigation_repaint_client_XJqq5sMcRG,
  check_outdated_build_client_GcpJMV8KYD,
  chunk_reload_client_f7Zq6oBQUy,
  plugin_vue3_Rt1wvYoGLz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_A45YlfBOgf,
  i18n_puByCiRaRV,
  unocss_MzCDxu9LMj,
  pwa_icons_vOfwvGoMCY,
  pwa_client_0hc9zT1UqI,
  _05_sentry_j5CkoUzNHJ,
  trpc_0KeCZOBKLF,
  vue_query_wrmMkNpEpe,
  vuetify_7h9QAQEssH
]