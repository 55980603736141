import { compare, hash } from "bcryptjs";
import type { UserLoginArgsSchema } from "@mono/validation/lib/Auth";
import { useSettingsStore } from "./settings";
import type { IndexedDBSchemas } from "@/composables/useIndexDB";

export type UserSchema = IndexedDBSchemas["users"];
type UserDetails = Omit<UserSchema, "pinCode" | "refreshToken">;

export const useAuthStore = defineStore("auth", () => {
  // states
  const user = ref<UserDetails>();
  const users = ref<UserSchema[]>([]);
  const setupSkipped = ref(false);

  // composables
  const router = useRouter();
  const { setTokens, resetTokens } = useAuthStorage();
  const { cache } = useIndexDB();
  const settingsStore = useSettingsStore();

  // computed
  const isUserCached = computed(
    () => users.value.find((u) => u.id === user.value?.id) != null
  );
  const userRole = computed(() =>
    user.value?.roles.find(
      (role) => role.orgId === settingsStore.settings?.orgId
    )
  );

  // reset handler
  const resetUser = () => {
    // reset auth local storage
    resetTokens();

    // reset user
    user.value = undefined;
    setupSkipped.value = false;
  };

  // forgetUser
  const forgetUser = async (userId?: string) => {
    const computedId = userId ?? user.value?.id;

    if (!computedId) return;
    try {
      await cache.users.delete(computedId);
      users.value = users.value.filter((user) => user.id !== userId);
    } catch (error) {
      console.error("Error forgetting user:", error);
    }
  };

  // fetch user details handler
  const fetchUserDetails = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const [userDetails, orgAcl] = await Promise.all([
        trpcClient.user.details.query(),
        trpcClient.organizations.getOrganizationACL.query(),
      ]);
      // update cached user details in indexDB if exists
      const updatedData: UserDetails = {
        email: userDetails.data.email,
        fullName: userDetails.data.fullName,
        id: userDetails.data.id,
        roles: orgAcl.data,
      };

      await cache.users.updateOne(userDetails.data.id, updatedData);

      return updatedData;
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  // check user ACL
  const checkUserACL = (roles: UserDetails["roles"]) => {
    if (!roles || !roles.length) return false;

    return roles.some(
      (role) =>
        role.orgId === settingsStore.settings?.orgId &&
        (role.isManager || role.isOwner || role.hasPosAccess)
    );
  };

  // get cached user details
  const getCachedUserDetails = (id?: UserDetails["id"]) => {
    const computedId = id ?? user.value?.id;

    if (!computedId) return;

    return cache.users.get(computedId);
  };

  const getOrgAccessToken = async (orgId: string) => {
    const { trpcClient } = useTrpcClient();
    try {
      const { token } = await trpcClient.auth.getOrgAccessToken.query(
        {
          orgId,
        },
        {
          context: {
            skipBatch: true,
          },
        }
      );

      setTokens({ token: null, refreshToken: null, orgToken: token });

      return { success: true };
    } catch (error) {
      console.error("Error getting org access token:", error);
      return { success: false };
    }
  };

  // Debounce the refreshTokens function to prevent multiple calls
  const refreshTokens = async (refreshToken?: string) => {
    const { trpcClient } = useTrpcClient();
    const { authStorage } = useAuthStorage();
    const { settings } = useSettingsStore();

    const refreshTokenNormalized =
      refreshToken ?? authStorage.value.refreshToken;

    try {
      if (refreshTokenNormalized == null) {
        throw new Error("No refresh token found");
      }

      const tokens = await trpcClient.auth.refreshTokens.mutate(
        {
          refreshToken: refreshTokenNormalized,
          orgId: settings?.orgId,
        },
        {
          context: {
            skipBatch: true,
          },
        }
      );

      setTokens({
        token: tokens.token,
        refreshToken: tokens.refreshToken,
        orgToken: tokens.orgToken,
      });

      return { success: true, refreshToken: tokens.refreshToken };
    } catch (error) {
      console.error("Error refreshing tokens:", error);
      logout();
      return { success: false };
    }
  };

  // offline login
  const offlineLogin = async ({
    userId,
    userPin,
  }: {
    userId: UserDetails["id"];
    userPin: string;
  }) => {
    try {
      // check if the user is saved in indexDB by the userId
      const cachedUser = await getCachedUserDetails(userId);

      // if user is not found, reset the user
      if (cachedUser == null) {
        resetUser();
        forgetUser(userId);
        return { invalidLogin: true, success: true };
      }

      // check if the pinCode matches
      const isPinMatch = await compare(userPin, cachedUser.pinCode);
      if (!isPinMatch) {
        resetUser();
        return { invalidLogin: true, success: true };
      }

      // check user ACL
      const hasAccess = checkUserACL(cachedUser.roles);

      if (!hasAccess) {
        resetUser();
        return { invalidLogin: true, success: true };
      }

      // check if refreshToken exists
      // if refreshToken does not exist, set the user with cached user data
      if (cachedUser.refreshToken == null) {
        user.value = cachedUser;
        return { invalidLogin: true, success: true };
      }

      // try refreshing the token if refreshToken exists
      const { refreshToken, success } = await refreshTokens(
        cachedUser.refreshToken
      );

      // if refreshToken is refreshed, update the cached user with the new refreshToken and fetch user details
      if (success === true && refreshToken != null) {
        await cache.users.updateOne(cachedUser.id, {
          refreshToken,
        });

        user.value = await fetchUserDetails();
      }

      // if refreshToken refresh failed, set the user with cached user data
      // and set the refreshToken to with the cached user refreshToken
      if (success === false) {
        user.value = cachedUser;

        setTokens({
          token: null,
          refreshToken: cachedUser.refreshToken,
          orgToken: null,
        });
      }
      return { invalidLogin: false, success: true };
    } catch {
      resetUser();
      return { success: false };
    }
  };

  // Login mutation
  const login = async ({
    email,
    password,
    rememberMe,
  }: UserLoginArgsSchema) => {
    const { trpcClient } = useTrpcClient();

    try {
      const { token, refreshToken } = await trpcClient.auth.loginUser.mutate({
        email,
        password,
        rememberMe,
      });

      setTokens({ token, refreshToken, orgToken: null });

      // fetch user details
      const userDetails = await fetchUserDetails();

      if (userDetails === undefined) {
        resetUser();
        return { invalidLogin: true, success: true };
      }

      if (settingsStore.settings !== undefined) {
        // check user ACL
        const hasAccess = checkUserACL(userDetails.roles);

        if (hasAccess === false) {
          resetUser();
          return { invalidLogin: true, success: true };
        }
      }

      user.value = userDetails;

      const isCached = users.value.find((u) => u.id === userDetails.id);

      // if user is already cached, refresh the user refeshToken
      if (isCached && refreshToken != null) {
        await cache.users.updateOne(userDetails.id, {
          refreshToken,
        });
      }

      return { invalidLogin: false, success: true };
    } catch {
      resetUser();
      return { invalidLogin: true, success: false };
    }
  };

  // Logout mutation
  const logout = () => {
    router.push({ name: "index", force: true, replace: true });
    resetUser();
  };

  // setup user
  const setupUser = async (pinCode: UserSchema["pinCode"]) => {
    const { authStorage } = useAuthStorage();
    if (!user.value || authStorage.value.refreshToken == null) return;

    // needed to get the user roles without proxy
    const userRoles = user.value.roles.map((role) => ({
      ...role,
    }));

    const userObj: UserSchema = {
      id: user.value.id,
      email: user.value.email,
      fullName: user.value.fullName,
      roles: userRoles,
      pinCode: await hash(pinCode, 12),
      refreshToken: authStorage.value.refreshToken,
    };

    const newUserId = await cache.users.upsert(userObj);

    if (!newUserId) throw new Error("Failed to save user");

    if (users.value.every((u) => u.id !== newUserId)) {
      users.value.push(userObj);
    }
  };

  // init auth store handler
  const init = async () => {
    // fetch all users from indexDB
    users.value = await cache.users.getAll();

    const userDetails = await fetchUserDetails();

    if (!userDetails) return;

    user.value = userDetails;
  };

  const clear = async () => {
    try {
      await cache.users.clear();
      users.value = [];
    } catch (error) {
      console.error("Error clearing users:", error);
    }
  };

  return {
    // getters
    user,
    isUserCached,
    users,
    setupSkipped,
    userRole,
    // setters
    login,
    offlineLogin,
    refreshTokens,
    logout,
    resetUser,
    init,
    setupUser,
    forgetUser,
    clear,
    getCachedUserDetails,
    getOrgAccessToken,
  };
});
