let syncInterval: NodeJS.Timeout;

export const useAppLifeCycle = () => {
  // init stores
  const authStore = useAuthStore();
  const settingsStore = useSettingsStore();

  const posStore = usePosStore();
  const productStore = useProductStore();
  const contactStore = useContactStore();
  const invoiceStore = useInvoiceStore();

  const printHelper = usePrintHelper();
  const terminalStore = useTerminalStore();

  const authStorage = useAuthStorage();
  const { isOnline } = useNetworkCheck();

  const init = async () => {
    if (settingsStore.settings !== undefined) {
      return;
    }

    await Promise.allSettled([
      settingsStore.init(),
      authStore.init(),
      isOnline(),
    ]);

    // printHelper.init();
  };

  const initDashboard = async () => {
    if (!authStorage.isLoggedIn.value || settingsStore.settings == null) {
      return;
    }

    await authStore.getOrgAccessToken(settingsStore.settings.orgId);

    await Promise.allSettled([
      posStore.init(),
      productStore.init(),
      contactStore.init(),
      terminalStore.init(),
    ]);

    // waiting for posStore initializing
    await Promise.all([invoiceStore.init(), printHelper.preloadPrintHelper()]);
    // sync invoices every 5 minutes
    syncInterval = setInterval(
      () => {
        invoiceStore.syncInvoices();
      },
      1000 * 60 * 5
    ); // 5 minutes
  };

  const clear = async (
    options: { clearInvoices?: boolean } = { clearInvoices: false }
  ) => {
    const isConnected = await isOnline();

    if (!isConnected) {
      throw new Error("No internet connection");
    }

    clearInterval(syncInterval);

    if (options.clearInvoices) {
      await invoiceStore.clear();
    }

    await Promise.allSettled([
      posStore.clear(),
      productStore.clear(),
      terminalStore.clear(),
      contactStore.clear(),
    ]);

    await Promise.allSettled([settingsStore.clear(), authStore.clear()]);
  };

  return {
    init,
    initDashboard,
    clear,
  };
};
