import { useSettingsStore } from "@/stores/settings";
import { generateReference } from "@/utils/helpers";

type PointOfSaleQuery = APIOutputs["pointOfSales"]["getOnePOS"];

export type PosSchema = {
  id: string;
  pointOfSale: PointOfSaleQuery["data"];
  reference: PointOfSaleQuery["references"];
  cashAccountLastRunningTotal: APIOutputs["ledger"]["general"]["getRunningTotal"]["data"][number];
};

export const usePosStore = defineStore("pos", () => {
  // states
  const pointOfSale = ref<PosSchema["pointOfSale"]>();
  const posReference = ref<PosSchema["reference"]>();
  const cashAccountLastRunningTotal =
    ref<PosSchema["cashAccountLastRunningTotal"]>();
  const posLogoUrl = ref<string>();

  // computed
  const hasPaymentMethods = computed(
    () =>
      (pointOfSale.value?.organization?.receiptPaymentMethods.length || 0) > 0
  );

  const posTitle = computed(
    () =>
      `${pointOfSale.value?.name} - ${pointOfSale.value?.organization?.name}`
  );

  // composables
  const { cache } = useIndexDB();

  // fetch pointOfSale handler
  const fetchPointOfSale = async (id: string) => {
    const { trpcClient } = useTrpcClient();

    const { data, references } = await trpcClient.pointOfSales.getOnePOS.query({
      id,
    });

    return {
      pointOfSale: data,
      posReference: references,
    };
  };

  // fetch cashAccountLastRunningTotal handler
  const fetchCashAccountLastRunningTotal = async ({
    cashAccountId,
    financialYearOffset = 0,
  }: {
    cashAccountId: string;
    financialYearOffset?: number;
  }) => {
    const { trpcClient } = useTrpcClient();

    const curDate = new Date();
    const [toDate] = new Date(
      curDate.getTime() - curDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T");

    const { data } = await trpcClient.ledger.general.getRunningTotal.query({
      where: {
        accountId: { _eq: cashAccountId },
        transactionDate: { _lte: toDate },
        isConfirmed: { _eq: true },
        financialYearOffset,
      },
      codeComparisonLength: 7,
    });

    return data?.[0] ?? undefined;
  };

  // update cashAccountLastRunningTotal handler
  const updateCashAccountLastRunningTotal = async () => {
    const { settings } = useSettingsStore();

    try {
      if (!settings || !pointOfSale.value) {
        throw new Error("Settings not found");
      }

      const runningTotal = await fetchCashAccountLastRunningTotal({
        cashAccountId: pointOfSale.value.cashAccountId,
        financialYearOffset:
          pointOfSale.value.organization.transactionsSettings
            ?.financialYearOffset,
      });

      cashAccountLastRunningTotal.value = runningTotal;
      await cache.pos.updateProperty(
        "cashAccountLastRunningTotal",
        runningTotal
      );
    } catch (error) {
      console.error("Error updating cash account last running total:", error);
    }
  };

  // fetch data handler
  const fetchData = async () => {
    const { settings } = useSettingsStore();
    const { logout } = useAuthStore();
    const { clear } = useAppLifeCycle();

    let posData: PosSchema | undefined;

    try {
      if (!settings) {
        throw new Error("Settings not found");
      }

      const { pointOfSale, posReference } = await fetchPointOfSale(
        settings.posId
      );

      const cashAccountLastRunningTotal =
        await fetchCashAccountLastRunningTotal({
          cashAccountId: pointOfSale.cashAccountId,
          financialYearOffset:
            pointOfSale.organization.transactionsSettings?.financialYearOffset,
        });

      // get reference from cache
      const referenceCache = await cache.pos.get();

      // if reference is not available, use the one from the server
      // if reference is available, use the one from the cache (maintain the counter)
      const reference = referenceCache?.reference ?? posReference;

      posData = {
        cashAccountLastRunningTotal,
        pointOfSale,
        id: pointOfSale.id,
        reference,
      };

      // cache data
      await cache.pos.save(pointOfSale.id, posData);
    } catch (error) {
      if (isNetworkError(error)) {
        // if network error, use cached data
        const cachedData = await cache.pos.get();

        posData = cachedData;
      } else if ((error as APIError).data?.code === "NOT_FOUND") {
        // if pos not found, clear all cache
        await clear();
      }
    } finally {
      // if data is available, set it
      if (posData) {
        pointOfSale.value = posData.pointOfSale;
        posReference.value = posData.reference;
        cashAccountLastRunningTotal.value = posData.cashAccountLastRunningTotal;

        // create image tag for logo to pre-cache it
        if (posData.pointOfSale.organization.organizationLogosUrls.posLogoUrl) {
          await fetch(
            posData.pointOfSale.organization.organizationLogosUrls.posLogoUrl,
            {
              mode: "no-cors",
            }
          );
          posLogoUrl.value =
            posData.pointOfSale.organization.organizationLogosUrls.posLogoUrl;
        }
      } else {
        logout();
      }
    }
  };

  // increment reference handler
  const incrementReferences = async ({
    transactionDate,
    disableInvoice = false,
    disableReceipt = false,
    noOfReceipts = 1,
  }: {
    transactionDate: string;
    disableInvoice?: boolean;
    disableReceipt?: boolean;
    noOfReceipts?: number;
  }) => {
    if (!pointOfSale.value || !posReference.value)
      throw new Error("POS is invalid");

    const posReferenceData = { ...posReference.value };

    let invoiceReference: string | undefined;
    let receiptReferences: string[] | undefined;

    if (!disableInvoice) {
      invoiceReference = generateReference({
        keyPath: "posInvoices",
        referenceData: posReferenceData,
        transactionDate,
      });
      // increment counter
      posReferenceData.posInvoicesReferenceCounter += 1;
    }

    if (!disableReceipt) {
      receiptReferences = Array.from({ length: noOfReceipts }, () => {
        const receiptReference = generateReference({
          keyPath: "posReceipts",
          referenceData: posReferenceData,
          transactionDate,
        });

        return receiptReference;
      });

      // increment counter
      posReferenceData.posReceiptsReferenceCounter += noOfReceipts;
    }

    await cache.pos.updateProperty("reference", posReferenceData);

    posReference.value = posReferenceData;

    return { invoiceReference, receiptReferences };
  };

  // clear
  const clear = async () => {
    await cache.pos.clear();
    pointOfSale.value = undefined;
    posReference.value = undefined;
    cashAccountLastRunningTotal.value = undefined;
  };

  // init
  const init = async () => {
    await fetchData();
  };

  return {
    // getters
    pointOfSale,
    posReference,
    cashAccountLastRunningTotal,
    hasPaymentMethods,
    posLogoUrl,
    posTitle,
    // setters
    clear,
    init,
    incrementReferences,
    fetchData,
    updateCashAccountLastRunningTotal,
  };
});
