import { useStorage } from "@vueuse/core";
import type { AuthTokens } from "@/utils/types/auth";

const authStorage = useStorage<AuthTokens>("auth", {
  token: null,
  refreshToken: null,
  orgToken: null,
});

const isLoggedIn = computed(() => !!authStorage.value.refreshToken);

export const useAuthStorage = () => {
  const setTokens = ({
    token,
    refreshToken,
    orgToken,
  }: {
    token?: string | null;
    refreshToken?: string | null;
    orgToken?: string | null;
  }) => {
    if (token != null) {
      authStorage.value.token = token;
    }

    if (refreshToken != null) {
      authStorage.value.refreshToken = refreshToken;
    }

    if (orgToken != null) {
      authStorage.value.orgToken = orgToken;
    }
  };

  const resetTokens = () => {
    authStorage.value.token = undefined;
    authStorage.value.refreshToken = undefined;
    authStorage.value.orgToken = undefined;
  };

  return {
    authStorage,
    isLoggedIn,
    setTokens,
    resetTokens,
  };
};
