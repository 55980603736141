import { useSettingsStore } from "./settings";

export type ProductSchema = APIOutputs["products"]["getAllPOS"]["data"][number];
export type ProductCategorySchema =
  APIOutputs["productCategories"]["getAllPOS"]["data"][number];

export const useProductStore = defineStore("product", () => {
  // states
  const products = ref<ProductSchema[]>([]);
  const productCategories = ref<ProductCategorySchema[]>([]);

  const lastFetchedAt = ref<number>();

  // composables
  const { cache } = useIndexDB();

  // fetchData handler
  const fetchData = async () => {
    const { trpcClient } = useTrpcClient();
    const { settings } = useSettingsStore();
    const { logout } = useAuthStore();

    let fetchedData:
      | {
          products: ProductSchema[];
          productCategories: ProductCategorySchema[];
        }
      | undefined;

    try {
      if (!settings) {
        throw new Error("Settings not found");
      }

      const [products, productCategories] = await Promise.all([
        trpcClient.products.getAllPOS.query(),
        trpcClient.productCategories.getAllPOS.query(),
      ]);

      // cache data
      await Promise.all([
        cache.products.saveMany(products.data),
        cache.categories.saveMany(productCategories.data),
      ]);

      fetchedData = {
        products: products.data,
        productCategories: productCategories.data,
      };

      lastFetchedAt.value = Date.now();
    } catch (error) {
      if (isNetworkError(error)) {
        // if network error, use cached data
        const [cachedProducts, cachedProductCategories] = await Promise.all([
          cache.products.getAll(),
          cache.categories.getAll(),
        ]);

        if (cachedProducts && cachedProductCategories) {
          fetchedData = {
            products: cachedProducts,
            productCategories: cachedProductCategories,
          };
        }
      } else {
        logout();
      }
    } finally {
      // if data is available, set it
      if (fetchedData) {
        products.value = fetchedData.products;
        productCategories.value = fetchedData.productCategories;
      }
    }
  };

  // clear handler
  const clear = async () => {
    await Promise.all([cache.products.clear(), cache.categories.clear()]);
    products.value = [];
    productCategories.value = [];
  };

  // init
  const init = async () => {
    await fetchData();
  };

  return {
    // getters
    products,
    productCategories,
    lastFetchedAt,
    // setters
    init,
    clear,
    fetchData,
  };
});
