import type { OrganizationAccessRoles } from "@mono/validation/lib/Organization";
import type {
  SalesInvoicesInsertInputSchema,
  SalesReceiptInvoiceUsagesInsertInputSchema,
} from "@mono/validation/lib/Sales";
import type { PosSchema } from "@/stores/pos";
import type { ProductCategorySchema } from "@/stores/product";
import type { PendingContactsSchema, ContactSchema } from "@/stores/contact";

type PendingSync = {
  isNotSynced?: boolean;
};

type invoice = Omit<
  SalesInvoicesInsertInputSchema,
  "salesReceiptInvoiceUsages"
> & {
  salesReceiptInvoiceUsages?: {
    data: (SalesReceiptInvoiceUsagesInsertInputSchema & {
      actualPaidAmount: number;
    })[];
  };
} & {
  callNumber?: number;
  isAnonymousCustomer?: boolean;
  memberId?: string;
} & PendingSync;

export interface IndexedDBSchemas {
  users: {
    id: string;
    email: string;
    fullName: string;
    refreshToken: string;
    pinCode: string;
    roles: {
      orgId: string;
      memberId: string | null;
      isOwner: boolean;
      isManager: boolean;
      hasPosAccess: boolean;
      memberRoles:
        | {
            accessRules: OrganizationAccessRoles;
          }[]
        | null;
    }[];
  };
  settings: {
    posId: string;
    orgId: string;
    lockedAt: string;
  };
  pos: PosSchema;
  products: ProductSchema;
  productCategories: ProductCategorySchema;
  contacts: ContactSchema;
  pendingContacts: PendingContactsSchema;
  invoices: invoice;
  pendingInvoices: invoice;
  terminal: {
    printers: {
      name: string;
      copies: number;
    }[];
    callNumbersEnabled: boolean;
    showProductsBarcode: boolean;
    showProductsTotalQuantity: boolean;
    showReferenceBarcode: boolean;
    locale: string;
  };
  dumpInvoices: { posId: string; invoices: invoice[] };
}

export type SchemaKeys = keyof IndexedDBSchemas;

let db: IDBDatabase;

const request = indexedDB.open(`pos-db`, 1);

// Event handlers for the database connection
request.onerror = (event) => {
  console.error("Database error: ", event);
};

request.onupgradeneeded = (event) => {
  console.debug("Database upgrade needed");

  // Save the IDBDatabase interface
  db = (event.target as any).result as IDBDatabase;

  // only create the object stores the first time the database is created
  // version == 0
  if (event.oldVersion !== 0) {
    return;
  }

  // Create an objectStores (schemas) for this database

  // user schema
  db.createObjectStore("users" as SchemaKeys, { keyPath: "id" });
  // settings schema
  db.createObjectStore("settings" as SchemaKeys, { keyPath: "posId" });
  // pos schema
  db.createObjectStore("pos" as SchemaKeys, { keyPath: "id" });
  // contacts schema
  db.createObjectStore("contacts" as SchemaKeys, { keyPath: "id" });
  // pendingContacts schema
  db.createObjectStore("pendingContacts" as SchemaKeys, { keyPath: "id" });
  // products schema
  db.createObjectStore("products" as SchemaKeys, { keyPath: "id" });
  // product Categories schema
  db.createObjectStore("productCategories" as SchemaKeys, { keyPath: "id" });
  // invoices schema
  db.createObjectStore("invoices" as SchemaKeys, { autoIncrement: true });
  // pendingInvoices schema
  db.createObjectStore("pendingInvoices" as SchemaKeys, {
    autoIncrement: true,
  });
  // draftInvoices schema
  db.createObjectStore("draftInvoices" as SchemaKeys, { keyPath: "id" });
  // terminal schema
  db.createObjectStore("terminal" as SchemaKeys, { autoIncrement: true });

  // dumpInvoices schema
  db.createObjectStore("dumpInvoices" as SchemaKeys, { keyPath: "posId" });
};

request.onsuccess = (event) => {
  console.debug("Database opened successfully");

  // Save the IDBDatabase interface
  db = (event.target as any).result as IDBDatabase;

  db.onerror = (e) => {
    // Generic error handler for all errors targeted at this database's
    // requests!
    console.error(`Database error: ${e}`);
  };
};

class Cache<T extends SchemaKeys> {
  private key: T;
  private db: IDBDatabase;

  constructor(key: T, database: IDBDatabase = db) {
    this.key = key;
    this.db = database;
  }

  static onRequest(request: IDBRequest) {
    return new Promise((resolve, reject) => {
      request.onsuccess = (event) => resolve(event);
      request.onerror = (event) => reject(event);
    });
  }

  async saveMany(data: IndexedDBSchemas[T][]) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requests = data.map((item) => {
      return objectStore.put(JSON.parse(JSON.stringify(item)));
    });

    await Promise.all(requests.map((req) => Cache.onRequest(req)));

    return requests.map((req) => req.result) as string[];
  }

  async add(data: IndexedDBSchemas[T]) {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .add(data);

    await Cache.onRequest(request);

    return request.result as string;
  }

  async addMany(data: IndexedDBSchemas[T][]) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requests = data.map((item) => {
      return objectStore.add(JSON.parse(JSON.stringify(item)));
    });

    await Promise.all(requests.map((req) => Cache.onRequest(req)));

    return requests.map((req) => req.result) as string[];
  }

  async upsert(data: IndexedDBSchemas[T]) {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .put(JSON.parse(JSON.stringify(data)));

    await Cache.onRequest(request);

    return request.result as string;
  }

  async get(id: string) {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .get(id);
    await Cache.onRequest(request);

    return request.result as IndexedDBSchemas[T];
  }

  async getAll() {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .getAll();

    await Cache.onRequest(request);

    return request.result as IndexedDBSchemas[T][];
  }

  async delete(id: string) {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .delete(id);

    await Cache.onRequest(request);
  }

  async deleteMany(ids: string[]) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requests = ids.map((id) => {
      return objectStore.delete(id);
    });

    await Promise.all(requests.map((req) => Cache.onRequest(req)));
  }

  async getAllKeys() {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .getAllKeys();

    await Cache.onRequest(request);

    return request.result as string[];
  }

  async updateOne(id: string, data: Partial<IndexedDBSchemas[T]>) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requestGet = objectStore.get(id);

    await Cache.onRequest(requestGet);

    if (!requestGet.result) return;

    const updatedUser = { ...requestGet.result, ...data };

    const requestPut = objectStore.put(updatedUser);

    await Cache.onRequest(requestPut);

    return requestPut.result as string;
  }

  async clear() {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .clear();

    await Cache.onRequest(request);
  }
}

class CacheSingle<T extends SchemaKeys> {
  private key: T;
  private db: IDBDatabase;
  private autoIncrement: boolean;
  private inlineKey?: string | number;

  constructor(
    key: T,
    autoIncrement?: boolean,
    inlineKey?: string | number,
    database: IDBDatabase = db
  ) {
    this.key = key;
    this.db = database;
    this.autoIncrement = !!autoIncrement;
    this.inlineKey = inlineKey;
  }

  async save(id: string, data: IndexedDBSchemas[T]) {
    // check if there exists a settings, only one settings to be saved
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requestGet = objectStore.getAllKeys();

    await Cache.onRequest(requestGet);

    const dataKey = this.autoIncrement ? Number(id) : id;

    if (requestGet.result.length && requestGet.result[0] !== dataKey) {
      throw new Error("There is already an exist entry");
    }

    const requestAdd = objectStore.put(
      data,
      this.autoIncrement ? dataKey : undefined
    );

    await Cache.onRequest(requestAdd);

    return requestAdd.result as string;
  }

  async get() {
    const request = this.db
      .transaction([this.key], "readonly")
      .objectStore(this.key)
      .getAll();
    await Cache.onRequest(request);

    return (request.result?.[0] as IndexedDBSchemas[T]) ?? undefined;
  }

  async updateProperty<K extends keyof IndexedDBSchemas[T]>(
    key: K,
    data: IndexedDBSchemas[T][K]
  ) {
    const objectStore = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key);

    const requestGet = objectStore.getAll();

    await Cache.onRequest(requestGet);

    if (!requestGet.result?.length) return;

    const updatedData = { ...requestGet.result[0], [key]: data };

    const requestPut = objectStore.put(updatedData, this.inlineKey);

    await Cache.onRequest(requestPut);

    return requestPut.result as string;
  }

  async clear() {
    const request = this.db
      .transaction([this.key], "readwrite")
      .objectStore(this.key)
      .clear();

    await Cache.onRequest(request);
  }
}

export const useIndexDB = () => {
  return {
    db,
    onRequest: Cache.onRequest,
    cache: {
      users: new Cache("users"),
      products: new Cache("products"),
      categories: new Cache("productCategories"),
      contacts: new Cache("contacts"),
      invoices: new Cache("invoices"),
      settings: new CacheSingle("settings"),
      terminal: new CacheSingle("terminal", true, 1),
      pos: new CacheSingle("pos"),
      pendingContacts: new Cache("pendingContacts"),
      pendingInvoices: new Cache("pendingInvoices"),
      dumpInvoices: new Cache("dumpInvoices"),
    },
  };
};
