<script setup lang="ts">
import { setZodI18nMap } from "@mono/validation";

const route = useRoute();
const router = useRouter();
const { t, locale } = useI18n();
const { watchTheme } = useThemeStorage();

const { resetUser } = useAuthStore();

setZodI18nMap({ t: t as any });
watchTheme();

const title = computed(
  () => `${route.meta.title || t("cloud-accounting")} | ${t("mezan-pos")}`
);

const dir = computed(() => (locale.value === "ar" ? "rtl" : "ltr"));

useHead({
  title,
});

(() => {
  // Don't init app if we are printing
  // required so the print-helper app can view the print page
  if (route.name === "print") {
    return;
  }

  resetUser();
  router.push({ name: "index", force: true, replace: true });
})();
</script>

<template>
  <Html
    :lang="locale"
    :dir="dir"
  >
    <NuxtLayout :class="locale === 'en' ? 'font-en' : 'font-ar'">
      <NuxtPwaManifest />
      <NuxtPage :key="locale" />
    </NuxtLayout>
  </Html>
</template>
