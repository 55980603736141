export default defineNuxtRouteMiddleware(async (route) => {
  // !solves an edge case where route.name isn't found
  if (!route?.name) return;

  const isInDashboard = route.name!.toString().startsWith("dashboard");
  const isInSetup = route.name!.toString().startsWith("setup");
  const isInPrint = route.name!.toString().startsWith("print");
  const { isLoggedIn } = useAuthStorage();
  const { init } = useAppLifeCycle();
  const settingsStore = useSettingsStore();

  if (isInPrint === true) return;

  await init();

  if (isLoggedIn.value === true) {
    // if is user is logged in

    //  pos settings are set navigate to dashboard if not already there
    if (
      isInPrint === false &&
      settingsStore.settings !== undefined &&
      (isInDashboard === false || isInSetup === true)
    ) {
      return navigateTo({ name: "dashboard" });
    }

    // pos settings are not set navigate to pos settings page if not already there
    if (
      isInPrint === false &&
      settingsStore.settings === undefined &&
      isInSetup === false
    ) {
      return navigateTo({ name: "setup" });
    }
  } else if (isInDashboard === true || isInSetup === true) {
    // if user is not logged in and is in dashboard or setup page navigate to index page
    return navigateTo({ name: "index" });
  }
});
