import { z } from "zod";
import {
  booleanComparisonExpSchema,
  dateStringSchema,
  emptyStringSchema,
  nonEmptyStringSchema,
  orderBySchema,
  phoneNumberSchema,
  phoneNumberSchemaRefinement,
  stringComparisonExpSchema,
  taxNumberSchema,
  translationSchema,
  uuidComparisonExpSchema,
} from ".";

export const contactsBoolExpSchema = z.object({
  orgId: uuidComparisonExpSchema.nullish(),
  isCustomer: booleanComparisonExpSchema.nullish(),
  isActive: booleanComparisonExpSchema.nullish(),
  countryId: stringComparisonExpSchema.nullish(),
  contactName: stringComparisonExpSchema.nullish(),
  contactOrgName: stringComparisonExpSchema.nullish(),
  contactPrimaryPhone: stringComparisonExpSchema.nullish(),
});

export type ContactsBoolExpSchema = z.infer<typeof contactsBoolExpSchema>;

export const contactsOrderBySchema = z.object({
  createdAt: orderBySchema.nullish(),
});

export type ContactsOrderBySchema = z.infer<typeof contactsOrderBySchema>;

export const contactsInsertInputSchema = z.object({
  id: z.string().uuid().optional(),
  orgId: z.string().uuid(),
  accountId: z.string().uuid(),
  isCustomer: z.boolean(),
  isActive: z.boolean(),
  contactName: nonEmptyStringSchema,
  contactNameTranslations: translationSchema,
  contactOrgName: nonEmptyStringSchema.or(emptyStringSchema),
  contactOrgNameTranslations: translationSchema,
  contactPrimaryEmail: z.string().email().or(emptyStringSchema),
  contactSecondaryEmail: z.string().email().or(emptyStringSchema),
  contactPrimaryPhone: phoneNumberSchema
    .superRefine(phoneNumberSchemaRefinement)
    .or(emptyStringSchema),
  contactSecondaryPhone: phoneNumberSchema
    .superRefine(phoneNumberSchemaRefinement)
    .or(emptyStringSchema),
  contactTaxNumber: taxNumberSchema,
  countryId: z.string(),
  city: z.string().trim().nullish(),
  cityTranslations: translationSchema,
  address: z.string().trim().nullish(),
  addressTranslations: translationSchema,
  zipcode: z.string().nullish(),
});

export type ContactsInsertInputSchema = z.infer<
  typeof contactsInsertInputSchema
>;

export const contactsSetInputSchema = contactsInsertInputSchema
  .omit({ isCustomer: true, orgId: true })
  .partial();

export type ContactsSetInputSchema = z.infer<typeof contactsSetInputSchema>;

export const contactsOpeningBalancesBoolExpSchema = z.object({
  orgId: uuidComparisonExpSchema.nullish(),
  isCustomer: booleanComparisonExpSchema.nullish(),
});

export type ContactsOpeningBalancesBoolExpSchema = z.infer<
  typeof contactsOpeningBalancesBoolExpSchema
>;

export const contactsOpeningBalancesOrderBySchema = z.object({
  createdAt: orderBySchema.nullish(),
  transactionDate: orderBySchema.nullish(),
});

export type ContactsOpeningBalancesOrderBySchema = z.infer<
  typeof contactsOpeningBalancesOrderBySchema
>;

export const contactsOpeningBalancesInsertInputSchema = z.object({
  orgId: z.string().uuid(),
  isCustomer: z.boolean(),
  transactionDate: dateStringSchema,
  contactId: z.string().uuid(),
  isConfirmed: z.boolean().optional(),
  description: z.string(),
  contactAccountId: z.string().uuid(),
  openingBalanceAccountId: z.string().uuid(),
  amount: z.number().int(),
  dimensionIds: z.array(z.string().uuid()).nullish(),
  transactionNote: z.string().nullish(),
  reference: z.string().nullish(),
});

export type ContactsOpeningBalancesInsertInputSchema = z.infer<
  typeof contactsOpeningBalancesInsertInputSchema
>;

export const contactsOpeningBalancesSetInputSchema =
  contactsOpeningBalancesInsertInputSchema
    .omit({
      orgId: true,
      isCustomer: true,
      contactId: true,
      isConfirmed: true,
      contactAccountId: true,
      openingBalanceAccountId: true,
    })
    .partial();

export type ContactsOpeningBalancesSetInputSchema = z.infer<
  typeof contactsOpeningBalancesSetInputSchema
>;

export const contactsInsertPosSchema = z.object({
  contactName: nonEmptyStringSchema,
  contactPrimaryEmail: z.string().email().or(emptyStringSchema),
  contactPrimaryPhone: phoneNumberSchema.superRefine(
    phoneNumberSchemaRefinement
  ),
  contactTaxNumber: taxNumberSchema,
  address: z.string().optional(),
});

export type ContactsInsertPosSchema = z.infer<typeof contactsInsertPosSchema>;
