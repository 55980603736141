import type { IndexedDBSchemas } from "@/composables/useIndexDB";

export type SettingsSchema = IndexedDBSchemas["settings"];

export const useSettingsStore = defineStore("settings", () => {
  // states
  const settings = ref<SettingsSchema>();

  // composables
  const { cache } = useIndexDB();

  // lock POS handler
  const setPosLock = async (
    posId: SettingsSchema["posId"],
    orgId: SettingsSchema["orgId"]
  ) => {
    const { trpcClient } = useTrpcClient();
    const { clear } = useAppLifeCycle();

    // check if user trying to lock POS for diff organization
    const invoices = await cache.invoices.getAll();
    if (
      invoices.length &&
      invoices[0].posId !== posId &&
      invoices[0].orgId !== orgId
    ) {
      // clear all cache with invoices
      await clear({ clearInvoices: true });
      throw new Error("Trying to set up different organization!");
    }

    const { data } = await trpcClient.pointOfSales.lock.mutate({
      id: posId,
    });

    const posSettings: SettingsSchema = {
      posId: data.id,
      lockedAt: data.lockedAt!,
      orgId: data.orgId,
    };

    settings.value = posSettings;

    await cache.settings.save(posSettings.posId, posSettings);

    return data;
  };

  // unlock POS handler
  const setPosUnlock = async (pos?: SettingsSchema) => {
    const { trpcClient } = useTrpcClient();

    if (settings.value == null && pos == null) return;

    await trpcClient.pointOfSales.unlock.mutate({
      id: pos?.posId ?? settings.value!.posId,
    });
  };

  // clear settings handler
  const clear = async () => {
    if (!settings.value) return;

    await cache.settings.clear();
    settings.value = undefined;
  };

  // init settings store handler
  const init = async () => {
    try {
      const data = await cache.settings.get();
      if (data) {
        settings.value = data;
      }
    } catch (error) {
      console.error("Error initializing settings:", error);
    }
  };

  return {
    // getters
    settings,
    // setters
    setPosLock,
    setPosUnlock,
    init,
    clear,
  };
});
