import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { type I18n, useI18n } from "vue-i18n";

export default defineNuxtPlugin((nuxtApp) => {
  //! Connect nuxt-i18n translations to vuetify
  //! https://github.com/nuxt-modules/i18n/issues/1831#issuecomment-1413214278

  const vuetify = createVuetify({
    ssr: false,
    locale: {
      //! This is a workaround to attach the i18n instance to vuetify
      //! to avoid having two locale states in the app
      //! https://github.com/nuxt-modules/i18n/issues/1831#issuecomment-1413214278
      adapter: createVueI18nAdapter({
        i18n: { global: nuxtApp.$i18n } as I18n,
        useI18n,
      }),
    },
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: "light",
      variations: {
        colors: ["gray"],
        lighten: 9,
        darken: 9,
      },
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#0A7B34",
            secondary: "#212121",
            info: "#03A9F4",
            warning: "#FFC107",
            error: "#E53935",
            success: "#0A7B34",
            gray: "#595959",
          },
        },
        dark: {
          dark: true,
          colors: {
            primary: "#0A7B34",
            secondary: "#f2f2f2",
            info: "#03A9F4",
            warning: "#FFC107",
            error: "#E53935",
            success: "#0A7B34",
            gray: "#bfbfbf",
          },
        },
      },
    },
    display: {
      mobileBreakpoint: "sm",
      thresholds: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
  });
  nuxtApp.vueApp.use(vuetify);
});
